import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Spinner from '../components/Spinner'
import { getAuth } from 'firebase/auth';
import { addDoc, collection, getDoc, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate, useParams } from 'react-router-dom';

export default function EditProfile() {
    const navigate = useNavigate()
    const auth = getAuth()
    const [loading, setLoading] = useState(false)
    
    const [profile, setProfile] = useState(null)
    const [formData, setFormData] = useState({
        type: "profiles",
        name: "",
        lastName: "",
        address:"",
        phoneNumber: "",
        job:"",
        Hours:"",
        team: "Team 1"
    })

    const {name, lastName, phoneNumber, address, job, team } = formData

    const params = useParams()

    useEffect(()=> {
        if(profile && profile.userRef !== auth.currentUser.uid){
            toast.error("You can't edit this profile")
            navigate("/")
        }
    },[auth.currentUser.uid, profile, navigate])

    useEffect(() => {
        setLoading(true)
        async function fetchListing() {
            const docRef = doc(db, "profiles", params.profileId)
            const docSnap = await getDoc(docRef)
            if(docSnap.exists()){
                setProfile(docSnap.data())
                setFormData({...docSnap.data()})
                setLoading(false)
            }else{
                navigate("/")
                toast.error("Listing does not exist")
            }
        }
        fetchListing()
    },[navigate, params.listingId ])

    function onChange(e) {     
        //text/boolean/numbers
        if(!e.target.files){
            setFormData((prevState) => ({
                ...prevState,
                [e.target.id]: e.target.value
            }))
        }
    }

    const formDataCopy = {
        ...formData,
        timestamp: serverTimestamp(),
        userRef: auth.currentUser.uid

    }

   async function onSubmit(e) {
    
        e.preventDefault();
        setLoading(true)    
       
        const docRef = doc(db, "profiles", params.profileId)
        await updateDoc(docRef, formDataCopy);
        setLoading(false)
        toast.success("Profile Edited")
        navigate(`/category/${formDataCopy.type}/${docRef.id}`)


    }
    

    if(loading){
        
        return <Spinner />
    }
    
  return (
    <main className='max-w-md px-2 mx-auto'>        
        <h1 className='text-3xl text-center mt-6 font-bold'>Edit Profile</h1>
        <form onSubmit={onSubmit}>
            
            <p className='text-lg mt-6 font-semibold '>Name</p>
            <input type="text" id='name' value={name} onChange={onChange} placeholder="Name" maxLength="32" minLength="2" required 
            className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6" />
            
            <p className='text-lg mt-6 font-semibold '>Last Name</p>
            <input type="text" id='lastName' value={lastName} onChange={onChange} placeholder="Last Name" maxLength="32" minLength="2" required 
            className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6" />
            
            <p className='text-lg mt-6 font-semibold '>Phone Number</p>
            <input type="text" id='phoneNumber' value={phoneNumber} onChange={onChange} placeholder="Phone Number" maxLength="10" minLength="2" required 
            className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6" />
            
            <p className='text-lg mt-6 font-semibold '>Job</p>
            <input type="text" id='job' value={job} onChange={onChange} placeholder="Job" maxLength="30" minLength="2" required 
            className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6" />
            
            <p className='text-lg mt-6 font-semibold '>Team</p>
            <select
              id="team"
              onChange={onChange}
              defaultValue={team}
              name="team"
              autoComplete="team-name"
              className="relative block w-full rounded-none rounded-t-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              <option value="Team 1">Team 1</option>
              <option value="Team 2">Team 2</option>
              <option value="Team 3">Team 3</option>
            </select>
            
            <p className='text-lg mt-6 font-semibold '>Address</p>
            <textarea 
            type="text" 
            id='address' 
            value={address} 
            onChange={onChange} 
            placeholder="Address" 
            required 
            className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6" />
            
            <button type='submit' className='mb-6 w-full px-7 py-3 bg-blue-600 text-white font-medium text-sm uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out'>Edit Profile</button>

        </form>
    </main>
  )
}

