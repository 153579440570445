import React from 'react'
import { Link } from 'react-router-dom'
import Moment  from "react-moment"
import {MdLocationOn} from "react-icons/md"
import {FaTrash} from "react-icons/fa"
import {MdEdit} from "react-icons/md"

export default function ProfileItem({profile, id, onEdit, onDelete}) {
    
  return (
    <li className='relative bg-white flex flex-col justify-between items-center shadow-md hover:shadow-lg rounded-md overflow-hidden transition-shadow duration-150 m-[10px] '>
        <Link className='contents' to={`/category/${profile.type}/${id}`}>
            <img className='h-[170px] w-full object-cover hover:scale-105 transition-scale duration-200 ease-in' 
            loading='lazy'
            src="https://images.unsplash.com/photo-1524504388940-b1c1722653e1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80" 
            alt="" />
            <Moment className='absolute top-2 left-2 bg-[#3377cc] text-white uppercase text-xs font-semibold rounded-md px-2 py-1 shadow-lg' fromNow >{profile.timestamp?.toDate( )}</Moment>
            <div className=' w-full p-[10px]'>
                <div className='flex items-center space-x-1'>
                    <MdLocationOn className='h-4 w-4 text-green-600' />
                    <p className='font-semibold text-sm mb-2 text-gray-600 truncate'>{profile.address}</p>
                </div>
                <p className=' font-semibold m-0 text-xl truncate'>{profile.name}</p>                

                <div className='flex items-center mt-[10px] space-x-3 '>
                    <div className='flex items-center space-x-1'>
                        <p className='font-bold text-xs'>{profile.lastName} </p>
                    </div>
                    <div className='flex items-center space-x-1'>
                        <p className='font-bold text-xs'>{profile.team}</p>
                    </div>
                </div>
            </div>           
        </Link>
        { onDelete && (
            <FaTrash 
            className='absolute bottom-2 right-2 h-[14px] cursor-pointer text-red-500' 
            onClick={() => onDelete(profile.id)} />
        )}
        { onEdit && (
             <MdEdit className='absolute bottom-2 right-10 h-4 cursor-pointer text-black-500' 
             onClick={() => onEdit(profile.id)} />
         )}
    </li>
  )
}
