
import React, { Component, Fragment, Suspense, lazy } from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import "./App.min.css";
import Home from "./pages/Home"
import Profile from "./pages/Profile"
import Offers from "./pages/Offers"
import SignIn from "./pages/SignIn"
import SignUp from "./pages/SignUp"
import ForgotPassword from "./pages/ForgotPassword"
import Header from "./components/Header"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from "./components/PrivateRoute"
import CreateListing from "./pages/CreateListing"
import CreateProfile from "./pages/CreateProfile"
import EditListing from "./pages/EditListing"
import Listing from "./pages/Listing"
import Profiles from "./pages/Profiles"
import EditProfile from "./pages/EditProfile"
import Footer from "./components/Footer";
import PageError from "./pages/PageError";

const InvoiceGenerator = lazy(() =>
  import("./views/invoice-generator/invoice-generator")
);
const InvoiceList = lazy(() => import("./views/invoice-list/invoice-list"));
const Settings = lazy(() => import("./views/settings/settings"));
const Invoicekid = lazy(() => import("./views/invoicekid/invoicekid"));

function App() {

  return (
    
    <Router>
      <React.Fragment >
      <Header />
      <div className="bg-gray-900" >
       <Suspense fallback={<div>Loading...</div>}>
      <Routes>

        <Route path="/" element={<Home/>} />        
        <Route render={() => <h1>404 Error</h1>} />
        <Route path="/profile" element={<PrivateRoute />}>
          <Route path="/profile" element={<Profile/>} />
        </Route>
        <Route path="/invoice" element={<PrivateRoute />}>
          <Route path="/invoice" element={<InvoiceGenerator/>} />
        </Route>
        <Route path="/invoices" element={<PrivateRoute />}>
           <Route path="/invoices" element={<InvoiceList/>} />
        </Route>
        <Route path="/settings" element={<PrivateRoute />}>
          <Route path="/settings" element={<Settings/>} />
        </Route>
        <Route path="/invoicekid" element={<PrivateRoute />}>
          <Route path="/invoicekid" element={<Invoicekid />} />
        </Route>


        <Route path="/sign-in" element={<SignIn/>} />
        <Route path="/sign-up" element={<SignUp/>} />
        <Route path="/offers" element={<Offers/>}  /> 
        <Route path="/category/:categoryName/:profilesId" element={<Profiles/>} />
        {/* <Route path="/category/:categoryName/:listingId" element={<Listing/>} /> */}
        <Route path="/forgot-password" element={<ForgotPassword/>} />
        <Route path="/create-listing" element={<PrivateRoute />}>
          <Route path="/create-listing" element={<CreateListing/>} />
        </Route>
        <Route path="/edit-listing" element={<PrivateRoute />}>
          <Route path="/edit-listing/:listingId" element={<EditListing/>} />
        </Route>
        <Route path="/create-profile" element={<PrivateRoute />}>
          <Route path="/create-profile" element={<CreateProfile/>} />
        </Route>
        

        <Route path="/edit-profile" element={<PrivateRoute />}>
          <Route path="/edit-profile/:profileId" element={<EditProfile/>} />
        </Route>
        <Route path="*" element={<PageError/>} />
      </Routes>
      

      <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="dark"
    />
    <ToastContainer />
    </Suspense>
    </div>
    <Footer />    
    </React.Fragment>
    </Router>
    
  );
}

export default App;






// import React, { Component, Suspense, lazy } from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import "./App.min.css";
// import { Navbar } from "./components/index.js";
// const InvoiceGenerator = lazy(() =>
//   import("./views/invoice-generator/invoice-generator")
// );
// const InvoiceList = lazy(() => import("./views/invoice-list/invoice-list"));
// const Settings = lazy(() => import("./views/settings/settings"));
// const Invoicekid = lazy(() => import("./views/invoicekid/invoicekid"));

// class App extends Component {
//   render() {
//     return (
//       <BrowserRouter>
//         <React.Fragment>
//           <Navbar />
//           <div className="container-fluid">
//             <Suspense fallback={<div>Loading...</div>}>
//               <Routes>
//                 <Route exact path="/" element={<InvoiceGenerator />} />
//                 <Route path="/invoices" element={<InvoiceList />} />
//                 <Route path="/settings" element={<Settings />} />
//                 <Route path="/invoicekid" element={<Invoicekid />} />
//                 <Route render={() => <h1>404 Error</h1>} />
//               </Routes>
//             </Suspense>
//           </div>
//         </React.Fragment>
//       </BrowserRouter>
//     );
//   }
// }

// export default App;
