import React, { useEffect, useState } from 'react'
import {Link, useLocation, useNavigate } from "react-router-dom"
import {getAuth, onAuthStateChanged} from "firebase/auth"
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import '../pages/home.css'
import logo from "../assets/images/logo-gwd.png"

export default function Header() {
  
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [pageState, setPageState] = useState("Sign In")
  const [menu, setMenu] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const auth = getAuth()
    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if(user){
          setMenu(true)
          setPageState("Profile")
        } else{
          setPageState("Sign In")

        }
      })
    },[auth])
    function pathMatchRoute(route) {
        if (route === location.pathname) {
            return true
        }
    }
    
  return (
      <div className="bg-gray-900 border-b shadow-sm sticky top-0 z-40 mb-30">
      <header className=" bg-gray-900 absolute inset-x-0 top-0 z-50">
        <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Gotta Web Design LLC</span>             
                <img
                className="h-20 w-auto"
                src={logo}
                alt="Gotta Web Design LLC"
              />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-sky-500"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
           
            <ul className="flex space-x-10 ">
            <li
              className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 border-b-[3px] border-b-transparent ${
                pathMatchRoute("/") && "text-sky-600 hover:text-sky-400 border-b-sky-600 hover:border-b-sky-400"
              }`}
              onClick={() => navigate("/")}
            >
              Home
            </li>  
            <li
              className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 border-b-[3px] border-b-transparent ${
                  pathMatchRoute("/invoice") && "text-sky-600 hover:text-sky-400 border-b-sky-600 hover:border-b-sky-400"
                 }`}
                 onClick={() => navigate("/invoice")}
               >
                 Invoice Generator
               </li>

               <li
                 className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 border-b-[3px] border-b-transparent ${
                   pathMatchRoute("/invoices") && "text-sky-600 hover:text-sky-400 border-b-sky-600 hover:border-b-sky-400"
                 }`}
                 onClick={() => navigate("/invoices")}
               >
                 List Invoices
               </li>

               <li
                 className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 border-b-[3px] border-b-transparent ${
                   pathMatchRoute("/settings") && "text-sky-600 hover:text-sky-400 border-b-sky-600 hover:border-b-sky-400"
                 }`}
                 onClick={() => navigate("/settings")}
               >
                 Settings
               </li>                
               <li
              className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 border-b-[3px] border-b-transparent ${
                 ( pathMatchRoute("/sign-in") || pathMatchRoute("/profile")) && "text-sky-600 hover:text-sky-400 border-b-sky-600 hover:border-b-sky-400"
              }`}
              onClick={() => navigate("/profile")}
            >
              {pageState}
            </li>
        </ul> 
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Gotta Web Design LLC</span>
                <img
                  className="h-20 w-auto"
                  src={logo}
                  alt="Gotta Web Design LLC"
                />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-sky-600"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                {/* <ul className="flex space-x-10 "> */}
                <ul className="list-none space-10">
                          <li
                           className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 border-b-[3px] border-b-transparent ${
                             pathMatchRoute("/") && "text-sky-600 hover:text-sky-400 border-b-sky-600 hover:border-b-sky-400"
                           }`}
                           onClick={() => {
                            navigate("/")
                            setMobileMenuOpen(false)
                          }}
                         >
                           Home
                         </li>  
                         <li
                           className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 border-b-[3px] border-b-transparent ${
                             pathMatchRoute("/invoice") && "text-sky-600 hover:text-sky-400 border-b-sky-600 hover:border-b-sky-400"
                           }`}
                           onClick={() => { 
                            navigate("/invoice")
                            setMobileMenuOpen(false)
                          }}
                         >
                           Invoice Generator
                         </li>
                         
                         <li
                           className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 border-b-[3px] border-b-transparent ${
                             pathMatchRoute("/invoices") && "text-sky-600 hover:text-sky-400 border-b-sky-600 hover:border-b-sky-400"
                           }`}
                           onClick={() => { 
                            navigate("/invoices")
                            setMobileMenuOpen(false)
                          }}
                         >
                           List Invoices
                         </li>
                         
                         <li
                           className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 border-b-[3px] border-b-transparent ${
                             pathMatchRoute("/settings") && "text-sky-600 hover:text-sky-400 border-b-sky-600 hover:border-b-sky-400"
                           }`}
                           onClick={() => {
                            navigate("/settings")
                            setMobileMenuOpen(false)
                          }}
                         >
                           Settings
                         </li>                 
                         <li
                           className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 border-b-[3px] border-b-transparent ${
                              ( pathMatchRoute("/sign-in") || pathMatchRoute("/profile")) && "text-sky-600 hover:text-sky-400 border-b-sky-600 hover:border-b-sky-400"
                           }`}
                           onClick={() => {
                            navigate("/profile")
                            setMobileMenuOpen(false)
                          }}
                         >
                           {pageState}
                         </li>
                    
                 </ul>
                 
                </div>                
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>   
  </div>
  )
}
