import React, {  Fragment, useEffect, useRef, useState  } from 'react'
import { doc, getDoc, deleteDoc, updateDoc, arrayUnion } from 'firebase/firestore'
import { toast } from 'react-toastify'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useParams, useNavigate } from 'react-router-dom'
import { db } from '../firebase'
import Spinner from '../components/Spinner'
import { getAuth } from 'firebase/auth';
import ProfileItem from '../components/ProfileItem'



export default function Profiles() {

    const params = useParams()
    const [profile, setProfile] = useState(null)
    const [loading, setLoading] = useState(true)
    const [isRun, setIsRun] = useState(false)
    const [isValid, setIsValid] = useState(null)
    const navigate = useNavigate()

    const [open, setOpen] = useState(false)
    const cancelButtonRef = useRef(null)


    useEffect(() => {
      if (isRun) { return; }
        async function fetchProfile() {
            const docRef = doc(db, "profiles", params.profilesId)
            const docSnap = await getDoc(docRef)
            if(docSnap.exists()){
              setProfile(docSnap.data())
                setLoading(false)
            }
        }
        fetchProfile()
        setIsRun(false)
    },[params.profilesId], setProfile, profile)

    async function onDelete(profileId) {
      if(window.confirm("Are you sure you want to delete?")){
        await deleteDoc(doc(db, "profiles", profileId))
        const updatedProfiles = profile.filter(
          (profile) => profile.id !== profileId
        )
        setProfile(updatedProfiles)
        toast.success("Successfully deleted the profile ")
      }
    }
    function onEdit(profileId) {
      navigate(`/edit-profile/${profileId}`)
    }

    const auth = getAuth()
    const [formData, setFormData] = useState({
        type:"profiles",
        date:"",
        start: "",
        end: "",
        breaks: "",
        authorization: "",
        profileRef: params.profilesId
    })

    const {type, date, start, end, breaks, authorization, profileRef } = formData


    function valid(s,e) {

        // var sHours = start.split(':')[0];
        // var sMinutes = end.split(':')[0];
      if(e >= s){
        return (
          console.log("valido"),
          setIsValid(true)          
          // toast("your time is not correct")
        )
      } 
      return (
        // window.alert("No es valido su tiempo"),
        // toast("Tiene un error al ingresar la hora de inicio y final"),
        setIsValid(false),
        setFormData({
          type:"profiles",
          date:"",
          start: "",
          end: "",
          breaks: "",
          authorization: "",
          profileRef: params.profilesId
    }),
    setOpen(false),
        console.log("no es valido")
        )
    }
// console.log(start.split(':')[0])

    function onChange(e) {  
      setIsRun(false)
      setIsValid(null)
        if(!e.target.files){
            setFormData((prevState) => ({
                ...prevState,
                [e.target.id]: e.target.value
            }))
        }
    }

    const formDataCopy = {
        ...formData,
        // timestamp: serverTimestamp(),
        userRef: auth.currentUser.uid
    
    }
// console.log(start)

function counterHours(x, y, z) {
 let star = x.split(":").slice(0,1)
 let star2 = x.split(":").slice(1,2)
  let sumStart = ((parseFloat(star) * 60) + parseFloat(star2)) 

  let end = y.split(":").slice(0,1)
  let end2 = y.split(":").slice(1,2)
  let sumEnd = ((parseFloat(end) * 60) + parseFloat(end2)) 

  let break1 = parseFloat(z)

  //resta de horas only
  let restHoras = parseFloat(end) - parseFloat(star)

  //resta de minutes
  let restMinutes = parseFloat(end2) - parseFloat(star2)

  // let resultHorasMinutes = `${restHoras} Hour ${restMinutes} Minutes  `

  let minutes = (( sumEnd - sumStart) - break1) 
  // obtener horas 
  let horasMinutes  =  Math.floor(minutes / 60)
  let resulHorasMinutesWithoutMinutes = horasMinutes.toString().split(".").slice(0,1)
  //obtener minutos
  // let minutesHoras = horasMinutes.toString().split(".").slice(1,2)
  let resultMinutesHoras = parseFloat(minutes) % 60

  let finalresult = `${resulHorasMinutesWithoutMinutes}:${resultMinutesHoras}`

  // const horas = Math.floor(minutes/ 60);          
  // const min = minutes % 60;
  // const textoHoras = (`00${horas}`).slice(-2);
  // const textoMinutos = (`00${min}`).slice(-2);
  
  return finalresult
}

function refreshPage() {
  window.location.reload(false);
}

   async function onSubmit(e) {
       
       setIsRun(true);
        e.preventDefault();

        
        valid(start, end)
        if(!isValid){
          toast.warning("Tiene un error al ingresar la hora de inicio y final")
          return
        }
        

        setLoading(true)    

        const docRef = doc(db, "profiles", params.profilesId);

        await updateDoc(docRef, {
            hours: arrayUnion(formDataCopy)
        });

        setLoading(false)
        toast.success("hours created")
        setOpen(false)

        const docRef1 = doc(db, "profiles", params.profilesId)
            const docSnap = await getDoc(docRef1)
            if(docSnap.exists()){
              setProfile(docSnap.data())
                setLoading(false)
            }
            setFormData("")
        navigate(`/category/${formDataCopy.type}/${params.profilesId}`)
       
        
    }
        

    if(loading){
        
        return <Spinner />
    }

  return (
    <>
    <div className='max-w-6xl pd-3 mt-6 mx-auto'>
        {
        profile &&
          <>
          <h2 className='text-2xl text-center font-semibold mb-6'>{profile.name}</h2>
          <ul className='sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl-grid-cols-5 mt-6 mb-6'>
            
              <ProfileItem  
              key={profile.name} 
              id={params.profilesId} 
              profile={profile} 
              onDelete={()=>onDelete(params.profilesId)} 
              onEdit={()=>onEdit(params.profilesId)} 
              />
          </ul>  
                       
          </>
        }
        <button
        type="button"
        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={() => setOpen(true)}
         >Add Date and Time</button>
       


     <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Add hours for week
                    </Dialog.Title>
                    <main className='max-w-md px-2 mx-auto'>        
                  <h1 className='text-3xl text-center mt-6 font-bold'>Add Date and Time</h1>
                  <form onSubmit={onSubmit}>

                      <p className='text-lg mt-6 font-semibold '>Date</p>
                      <input  className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6" 
                      type="date" 
                      id="date" 
                      name="date"
                      defaultValue={date} onChange={onChange} placeholder="Date" required  />
                      <p className='text-lg mt-6 font-semibold '>Start Time</p>
                      <div className='flex'>
                      
                      </div>
                       <p className='text-lg mt-6 font-semibold '>Start Time</p>
                      <input  className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6" 
                      type="time"                      
                      min="05:00" max="21:00"
                      id="start" 
                      name="start" 
                      defaultValue={start} onChange={onChange} placeholder="Start" required  />
                      <p className='text-lg mt-6 font-semibold '>End Time</p>
                      <input  className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6" 
                      type="time" 
                      min="05:00" max="21:00"
                      id="end" 
                      name="end"
                      defaultValue={end} onChange={onChange} placeholder="End" required  />                     
                      
                      <p className='text-lg mt-6 font-semibold '>Breaks</p>
                      <select
                        id="breaks"
                        onChange={onChange}
                        defaultValue={breaks}
                        name="breaks"
                        className="relative block w-full rounded-none rounded-t-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option value="">--select--</option>
                        <option value="15">15 minutes</option>
                        <option value="30">30 minutes</option>
                        <option value="45">45 minutes</option>
                        <option value="60">60 minutes</option>
                        <option value="70">70 minutes</option>
                        <option value="80">80 minutes</option>
                        <option value="90">90 minutes</option>
                      </select>

                      <p className='text-lg mt-6 font-semibold '>Authorization</p>
                      <select
                        id="authorization"
                        onChange={onChange}
                        defaultValue={authorization}
                        name="authorization"
                        className="relative block w-full rounded-none rounded-t-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option value="">--select--</option>
                        <option value="No">did not show up</option>
                        <option value="license ">License </option>
                      </select>
                     
                      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                          type="Submit"
                          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                          // onClick={onSubmit()}
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                          onClick={() => setOpen(false)}
                          ref={cancelButtonRef}
                        > Cancel
                        </button>
                      </div>
                  </form>
                   </main>
                </div>
                </div>
                
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
      </div>
    <div className='max-w-6xl pd-3 mt-6 mx-auto'>      
        <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Users</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name, title, email and role.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add user
          </button>
        </div>
      </div>
      <div className="-mx-4 mt-8 sm:-mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                Date
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Start
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                End
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Break
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                License
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Hours
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            { profile &&  (
               profile.hours.map((x) => (
              <tr key={`${x.start} ${x.end}`}>
                <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{x.date}</td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{x.start}</td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{x.end}</td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{x.breaks} minutes</td>
                <td className="px-3 py-4 text-sm text-gray-500">{x.authorization}</td>
                <td className="px-3 py-4 text-sm text-gray-500">{counterHours(x.start, x.end, x.breaks)}</td>
                <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                  <a href="#" className="text-indigo-600 hover:text-indigo-900">
                    Edit<span className="sr-only">, {x.start}</span>
                  </a>
                </td>
              </tr>)
            )) 
          }
          </tbody>
        </table>
      </div>
    </div>
      </div>

    
    
    </>
  )
}
