import React, { useEffect, useState } from 'react'
import { getAuth, updateProfile } from 'firebase/auth'
import { collection, deleteDoc, doc, getDocs, orderBy, query, updateDoc, where } from 'firebase/firestore'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { db } from '../firebase'


export default function Profile() {
  const auth = getAuth()
  const navigate = useNavigate()
  const [changeDetail, setChangeDetail] = useState(false)

  const [profiles, setProfiles] = useState(null)
  const [loading, setLoading] = useState(true)
  const [formData, setFormData] = useState({
    name: auth.currentUser.displayName,
    email: auth.currentUser.email
   })
const {name, email} = formData

function onLogout() {
  auth.signOut()
  navigate("/")
  
}

function onChange(e) {
  setFormData((prevState) => ({
    ...prevState,
    [e.target.id]: e.target.value,
  }))
  
}

async function onSubmit() {
  try {
    if(auth.currentUser.displayName !== name){
      //update display name in firebase auth
      await updateProfile(auth.currentUser, {
        displayName: name,
      })

      //update name in the firestore auth

      const docRef = doc(db, "users", auth.currentUser.uid)
      await updateDoc(docRef, {
        name
      }) 
    }
    toast.success("Profile details updated")
  } catch (error) {
    toast.error("Could not update the profile details")
  }
}

useEffect(() => {
async function fetchUserProfiles() {
  const listingRef = collection(db, "profiles")
  const q = query(listingRef, where("userRef", "==", auth.currentUser.uid ), orderBy("timestamp", "desc"))
  const querySnap = await getDocs(q)
  let profilesAll = []
  querySnap.forEach((doc)=> {
    return profilesAll.push({
    id: doc.id,
    data: doc.data(),
  })})
  setProfiles(profilesAll)
  setLoading(false)
}
fetchUserProfiles()
},[auth.currentUser.uid])

 async function onDelete(profileId) {
  if(window.confirm("Are you sure you want to delete?")){
    await deleteDoc(doc(db, "profiles", profileId))
    const updatedProfiles = profiles.filter(
      (profile) => profile.id !== profileId
    )
    setProfiles(updatedProfiles)
    toast.success("Successfully deleted the profile ")
  }
}
function onEdit(profileId) {
  navigate(`/edit-profile/${profileId}`)
}


  return (
    <>
      <section className='pt-32 pl-5 pr-5 pb-5 max-w-6xl mx-auto flex justify-center items-center flex-col'>
        <h1 className='text-3xl text-center mt-6 font-bold text-white'>My Profile</h1>
        <div className='w-full md:w-[50%] mt-6 px-3'>
          <form>
            <input type="text" id="name" value={name} disabled={!changeDetail} onChange={onChange} className={`mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition ease-in-out ${changeDetail && "bg-red-200 focus:bg-red-200"}`}/>
            <input type="email" id="email" value={email} disabled className='mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition ease-in-out'/>
            <div className='flex justify-between whitespace-nowrap text-sm sm:text-lg mb-6'>
              <p className='flex items-center text-white'>Do you want change your name? 
                <span 
                onClick={() => {
                  changeDetail && onSubmit() 
                  setChangeDetail((prevState) => !prevState)
                }} 
                className='text-red-600 hover:text-red-700 transition ease-in-out duration-200 ml-1 cursor-pointer'>
                  {changeDetail ? "Apply change" : "Edit"}
                  </span>
              </p>
              <p onClick={onLogout} className='text-sky-600 hover:text-sky-800 transition duration-200 ease-in-out cursor-pointer'>Sign out</p>
            </div>
          </form>
          {/* <button type='submit' className='w-full bg-sky-600 text-white uppercase px-7 py-3 text-sm font-medium rounded shadow-md hover:bg-sky-700 transition duration-150 ease-out hover:shadow-lg active:bg-blue-800' >
            <Link to="/create-profile" className='flex justify-center items-center' >

              <FcHome className='mr-2 text-3xl bg-red-200 rounded-full p-1 border-2 ' />
                  Create Profile
           </Link>
          </button> */}
        </div>
      </section>
      {/* <div className='max-w-6xl pd-3 mt-6 mx-auto'>
        {
        !loading && profiles.length > 0 && (
          <>
          <h2 className='text-2xl text-center font-semibold mb-6'>Employees</h2>
          <ul className='sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl-grid-cols-5 mt-6 mb-6'>
            {profiles.map((profile) => (
              <ProfileItem  
              key={profile.id} 
              id={profile.id} 
              profile={profile.data} 
              onDelete={()=>onDelete(profile.id)} 
              onEdit={()=>onEdit(profile.id)} 
              />
            ))}
          </ul>  
                       
          </>
        )}
      </div>
      <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Our Teams</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">

          
          { !loading && profiles.length > 0 && (
          
              <Disclosure as="div"  className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">Team 1</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                   
                    <div className='max-w-6xl pd-3 mt-6 mx-auto'>
                          <ul className='sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl-grid-cols-5 mt-6 mb-6'>  
                          { profiles.filter(x => x.data.team === "Team 1").map((profile) => (                         
                              <ProfileItem  
                              key={profile.id} 
                              id={profile.id} 
                              profile={profile.data} 
                              onDelete={()=>onDelete(profile.id)} 
                              onEdit={()=>onEdit(profile.id)} 
                              />
                              ))}
                          </ul>  
                      </div>
                    </Disclosure.Panel>
                  </>
                    )}
              </Disclosure>
            )}
            { !loading && profiles.length > 0 && (
          
          <Disclosure as="div"  className="pt-6">
            {({ open }) => (
              <>
                <dt>
                  <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-base font-semibold leading-7">Team 2</span>
                    <span className="ml-6 flex h-7 items-center">
                      {open ? (
                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                      ) : (
                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                      )}
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="dd" className="mt-2 pr-12">
               
                <div className='max-w-6xl pd-3 mt-6 mx-auto'>
                      <ul className='sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl-grid-cols-5 mt-6 mb-6'>  
                      { profiles.filter(x => x.data.team === "Team 2").map((profile) => (                         
                          <ProfileItem  
                          key={profile.id} 
                          id={profile.id} 
                          profile={profile.data} 
                          onDelete={()=>onDelete(profile.id)} 
                          onEdit={()=>onEdit(profile.id)} 
                          />
                          ))}
                      </ul>  
                  </div>
                </Disclosure.Panel>
              </>
                )}
          </Disclosure>
        )}
        { !loading && profiles.length > 0 && (
          
          <Disclosure as="div"  className="pt-6">
            {({ open }) => (
              <>
                <dt>
                  <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-base font-semibold leading-7">Team 3</span>
                    <span className="ml-6 flex h-7 items-center">
                      {open ? (
                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                      ) : (
                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                      )}
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="dd" className="mt-2 pr-12">
               
                <div className='max-w-6xl pd-3 mt-6 mx-auto'>
                      <ul className='sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl-grid-cols-5 mt-6 mb-6'>  
                      { profiles.filter(x => x.data.team === "Team 3").map((profile) => (                         
                          <ProfileItem  
                          key={profile.id} 
                          id={profile.id} 
                          profile={profile.data} 
                          onDelete={()=>onDelete(profile.id)} 
                          onEdit={()=>onEdit(profile.id)} 
                          />
                          ))}
                      </ul>  
                  </div>
                </Disclosure.Panel>
              </>
                )}
          </Disclosure>
        )}
        { !loading && profiles.length > 0 && (
          
          <Disclosure as="div"  className="pt-6">
            {({ open }) => (
              <>
                <dt>
                  <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-base font-semibold leading-7">No Team</span>
                    <span className="ml-6 flex h-7 items-center">
                      {open ? (
                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                      ) : (
                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                      )}
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="dd" className="mt-2 pr-12">
               
                <div className='max-w-6xl pd-3 mt-6 mx-auto'>
                      <ul className='sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl-grid-cols-5 mt-6 mb-6'>  
                      { profiles.filter(x => x.data.team != "Team 1" && "Team 2" && "Team 3").map((profile) => (                         
                          <ProfileItem  
                          key={profile.id} 
                          id={profile.id} 
                          profile={profile.data} 
                          onDelete={()=>onDelete(profile.id)} 
                          onEdit={()=>onEdit(profile.id)} 
                          />
                          ))}
                      </ul>  
                  </div>
                </Disclosure.Panel>
              </>
                )}
          </Disclosure>
        )}
          </dl>
        </div>
      </div>
      </div>
      <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Users</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name, title, email and role.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add user
          </button>
        </div>
      </div>
      <div className="-mx-4 mt-8 sm:-mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                Name
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Team
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Phone
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Role
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            { !loading && profiles.length > 0 && (
            profiles.map((profile) => (
              <tr key={profile.data.timestamp}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                  {`${profile.data.name} ${profile.data.lastName}`}
                </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                  {profile.data.team}
                </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                  {profile.data.phoneNumber}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{profile.data.job}</td>
                <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                  <a href="#" className="text-indigo-600 hover:text-indigo-900">
                    Edit<span className="sr-only">, {profile.data.name}</span>
                  </a>
                </td>
              </tr>
            )))}
          </tbody>
        </table>
      </div>
      </div>                   */}
    
    </>
  )
}
