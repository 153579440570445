import {useState} from 'react'
import {Link, useNavigate} from "react-router-dom"
import {AiFillEyeInvisible, AiFillEye } from "react-icons/ai"
import OAuth from '../components/OAuth'
import { signInWithEmailAndPassword,  getAuth } from 'firebase/auth'
import { toast } from 'react-toastify'
import office from "../assets/svg/gwd-office.svg"

export default function SignIn() {
    const [showPassword, setShowPassword] = useState("")
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    })
    const {email, password } = formData;
    const navigate = useNavigate()
    
    function onChange(e) {
        setFormData((prevState) => ({
          ...prevState,
          [e.target.id]: e.target.value,
        }));
      }

      async function onSubmit(e) {
        e.preventDefault()
        try {
            const auth = getAuth()
            const userCredential = await signInWithEmailAndPassword(auth, email, password)
            if(userCredential.user){
                navigate("/")
            }

        } catch (error) {
            toast("Bad user credentials")
        }
      }
      
  return (
    <section className='pt-14'>
        <h1 className="text-3xl text-center mt-6 font-bold">Sign In</h1>
        <div className='flex justify-center flex-wrap items-center px-6 py-12 max-w-6xl mx-auto'>
            <div className='md:w-[67%] lg:w-[50%] mb-12 md:mb-6'>
                <img src={office} alt="key" 
                className='w-full rounded-2xl'/>
            </div>
            <div className='w-full md:w-[67%] lg:w-[40%] lg:ml-20'>
                <form onSubmit={onSubmit}>
                    <div>
                         <input className='mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out' 
                          type="email"
                          id='email'
                          value={email} 
                          onChange={onChange} 
                           placeholder="Email Address"
                             />
                    </div>
                    <div className='relative'>
                         <input className='mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out' 
                         type={showPassword ? "text" : "password"}
                         id='password'
                         value={password} 
                         onChange={onChange} 
                         placeholder="Password"
                         />
                         { showPassword ? <AiFillEyeInvisible className='absolute right-3  top-3 text-xl cursor-pointer' onClick={() => setShowPassword((prevState)=>!prevState) } /> : <AiFillEye  className='absolute right-3  top-3 text-xl cursor-pointer' onClick={() => setShowPassword((prevState)=>!prevState) } /> }
                    </div>
                    <div className='flex justify-between whitespace-nowrap text-sm sm:text-lg'>
                        <p className='mb-6  text-white'>Don't have a account?
                            <Link to="/sign-up" className='text-red-600 hover:text-red-700 transition duration-200 ease-in-out ml-1'>Register</Link>
                        </p>
                        <p>
                            <Link to="/forgot-password" className='text-sky-600 hover:text-sky-800 transition duration-200 ease-in-out ml-1'>Forgot Password!</Link>
                        </p>
                    </div>  
                    <button 
                      className='w-full bg-sky-600 text-white px-7 py-3 text-sm font-medium uppercase rounded shadow-md hover:bg-sky-700 transition duration-150 ease-in-out hover:shadow-lg active:bg-sky-800'
                      type="submit">
                          Sign In
                      </button>
                      <div className='flex items-center my-4 before:border-t  before:flex-1  before:border-gray-300 after:border-t  after:flex-1  after:border-gray-300' >
                          <p className='text-center font-semibold mx-4 '>OR</p>
                      </div>
                      <OAuth />
                </form>
               

            </div>
        </div>
    </section>
  )
}
